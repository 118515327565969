/*!
Theme Name: goto
Description: ごと用テーマ
*/
@import "~HTML5-Reset";
@import "_module";
@import "_init";
@import "_main";
@import "_underlayer-common";
@import "_home";
@import "_curry";
@import "_recipe";
@import "_recipe-detail";
@import "_furoshiki";
@import "_news-top";
@import "_news-detail";
